import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {createPresta, duplicateImageFromURL, getPrestaDoc} from "../firebase";
import {Backdrop, CircularProgress, Skeleton, Typography} from "@mui/material";
import SnackbarWithCustomBackground from "./snackbar";
import {useParams} from "react-router";
import {Gallery} from "./carouselpresta";
import Button from "@mui/material/Button";
import {useDataContext} from "./datacontext";
import pinMapGrey from "../assets/ph_map-pin_grey.png";
import eyeMap from "../assets/eyemap.svg";
import dollarIc from "../assets/dollaIc.svg";
import {HeaderHome} from "./headerHome";
import {cloneDeep} from "lodash";
import {fetchLatLong} from "./utils";
import {MapComp} from "./map";
function durationFormatMin(duration){
    const [hours, minutes] = duration.split('h');
    return parseInt(hours) === 0 ? `${minutes} min` : `${duration}`;
}
export default function Presta() {

    const { id } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { data, updateData } = useDataContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        updateData({});
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowBackdrop(true);
                    async function fetchPrestaData() {
                        try {
                            const userPresta = await getPrestaDoc(id);
                            if (userPresta!=null){
                                setPrestaObject(userPresta);
                                if (userPresta.selectedAddress?.length>2 && userPresta.isAddressVisible){
                                    try {
                                        const { latitude, longitude } = await fetchLatLong(userPresta.selectedAddress);
                                        setLat(latitude);
                                        setLong(longitude);
                                    } catch (e) {
                                        console.log(e.message);
                                    }
                                }
                                if (userPresta?.imageLinks[0]?.length<1){
                                    setPrestaObject({
                                        ...userPresta,
                                        imageLinks:["https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."]
                                    });
                                }
                            } else {
                                new Error("Presta doesn't exists")
                            }
                        } catch (e){
                            handleOpenSnackbar("Ooops quelque chose s'est mal passé, contactez nous et communiquez cette erreur => ", e);
                        }
                        setShowBackdrop(false);
                    }
                    await fetchPrestaData();
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => unregisterAuthObserver();
    }, []);

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [prestaObject, setPrestaObject] = useState({});
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const navigateHome = async () => {
        navigate("/home")
    }

    const handleModifyPresta = async () => {
        navigate("/modifypresta/"+id)
    }

    const handleOpenAddressInGmaps = (address) => {
        if (address.length>1){
            window.open("https://www.google.com/maps/place/" + address);
        }
    }

    /*if (Object.keys(prestaObject).length === 0) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }*/


    const handleDuplicatePresta = async () => {

        setShowBackdrop(true);

        let newObj = cloneDeep(prestaObject);
        let newArrayImElements = [];
        if (prestaObject?.imageLinks?.length>0){
            for (let image of prestaObject.imageLinks){
                try {
                    const link = await duplicateImageFromURL(image.imageLink);
                    newArrayImElements.push({
                        ...image,
                        imageLink : link,
                    })
                } catch (e) {
                    handleOpenSnackbar("Quelque chose s'est mal passé, veuillez réessayer ou nous contacter.");
                    setShowBackdrop(false);
                    return;
                }

            }
        }

        newObj = {
            ...newObj,
            imageLinks : [...newArrayImElements],
            status : "draft",
            title : newObj.title + " (Copie)"
        }

        try {
            const urlId = await createPresta(newObj);
            setShowBackdrop(false);
            navigate("/presta/"+urlId);
            window.location.reload();
        } catch (e) {
            console.log(e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, veuillez réessayer ou nous contacter.");
            setShowBackdrop(false);
        }
    }


    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {Object.keys(prestaObject).length === 0 ? (
                    <div className="sub-container-no-top">
                        <div style={{display:"flex", width:"100%", flexDirection:"column",
                            justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                            <Skeleton style={{borderRadius:"15px"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                            <Skeleton style={{marginTop:"50px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        </div>
                    </div>
                ) :
                (
            <div className="sub-container-no-top">
                <span className="title-style">Prestation</span>
                <div style={{display:"flex", marginTop:"18px", width:"100%"}}>
                    <span className="title-style-presta">{prestaObject.title}</span>
                </div>

                <div style={{display:"flex", flexDirection:"row", marginTop:"10px", width:"100%"}}>
                    <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5"}}>
                    <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                        {prestaObject.price}
                    </span>
                    </div>
                    <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", marginLeft:"10px"}}>
                    <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                        {durationFormatMin(prestaObject.prestaDuration)}
                    </span>
                    </div>
                </div>

                <div style={{display:"flex", width:"100%", marginTop:"20px"}}>
                    {prestaObject.imageLinks.length>0 ? <Gallery urls={prestaObject.imageLinks}>
                    </Gallery> : <Gallery urls={[{
                        imageLink:"https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA..",
                        vlink:""
                    }]}>
                    </Gallery>}

                </div>

                <p style={{fontSize:"16px", marginTop:"20px", display:"flex", width:"100%", whiteSpace:"pre-line",wordWrap: "break-word"}}>
                    {prestaObject.description}
                </p>

                {prestaObject.selectedAddress.length > 2 && (
                    prestaObject?.isAddressVisible ? (
                        <div className="sub-container-no-top">
                            <div onClick={()=>handleOpenAddressInGmaps(prestaObject.selectedAddress)}
                                 style={{
                                     display: "flex",
                                     //background: "linear-gradient(90deg, #4808FF 0%, #9747FF 100%)",
                                     marginTop: "63px",
                                     minHeight: "10px",
                                     borderRadius: "15px",
                                     boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                     width: "100%",
                                     cursor: "pointer",
                                     flexDirection: "column", // Change to column to stack items vertically
                                 }}
                            >
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom:"25px"
                                    }}
                                >
                                    <div style={{ width: "10%" }}>
                                        <img src={pinMapGrey} style={{width:"30px", height:"30px"}}/>
                                    </div>
                                    <div
                                        style={{
                                            width: "90%", // Adjusted width to make space for the close icon
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <span style={{color:"black"}}>{prestaObject.selectedAddress}</span>
                                    </div>
                                </div>
                            </div>
                            {
                                lat!==null && long!==null && (
                                    <MapComp latitude={lat} longitude={long} />
                                )
                            }
                        </div>
                    ) : (
                        <div
                             style={{
                                 display: "flex",
                                 //background: "linear-gradient(90deg, #4808FF 0%, #9747FF 100%)",
                                 marginTop: "63px",
                                 minHeight: "10px",
                                 borderRadius: "15px",
                                 boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                 width: "100%",
                                 cursor: "mouse",
                                 flexDirection: "column", // Change to column to stack items vertically
                             }}
                        >
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row", // Change to row to have icon and content side by side
                                    alignItems: "start", // Center icon and content vertically
                                    padding: "1px 20px", // Add padding to create some space around the content
                                    marginBottom:"25px"
                                }}
                            >
                                <div style={{ width: "10%" }}>
                                    <img src={eyeMap} style={{width:"30px", height:"30px"}}/>
                                </div>
                                <div
                                    style={{
                                        width: "90%", // Adjusted width to make space for the close icon
                                        marginLeft: "10px",
                                    }}
                                >
                                    <span style={{color:"black"}}>L’adresse de cette prestation est masquée et sera révélée par le beauty artiste à la validation de la réservation.</span>
                                </div>
                            </div>
                        </div>
                    )

                )}

                {
                    prestaObject?.isDeposit && (
                        <div style={{width:"100%"}}>
                            <div style={{display: "flex", padding: "20px 20px 20px 20px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start",
                                gap: "30px", borderRadius: "15px",
                                background: "linear-gradient(180deg, #DAE0E5 0%, #FFF 100%)",
                                boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)", marginTop:"60px"}}>

                                <div style={{flexDirection:"column", display:"flex", width:"100%"}}>

                                    <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                <span style={{fontWeight: 700, lineHeight: "20px"}}>
                                                    Un acompte sera requis pour valider la réservation.
                                                </span>
                                        <img style={{marginLeft:"20px",marginRight:"7px"}} src={dollarIc}/>
                                    </div>

                                    <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:"10px"}}>
                                                <span style={{ lineHeight: "20px"}}>
                                                    Montant
                                                </span>
                                        <div style={{display: "flex", padding:" 5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", fontWeight:700, fontSize:"14px"}}>
                                            {prestaObject?.deposit?.amount}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="button-container" style={{marginTop:"70px"}}>
                    <Button onClick={handleModifyPresta} className="button-style button-style-noborders" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Modifier
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button onClick={handleDuplicatePresta} className="blue-button button-style-noborders" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Dupliquer
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateHome}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Retour
                        </Typography>
                    </Button>
                </div>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>)}
        </div>
    )
}